import {useEffect, useMemo, useRef} from 'react';
import {BufferGeometry, BufferAttribute, Vector2, Vector3} from 'three';
import type {Points} from 'three';
import {useFrame, useThree} from '@react-three/fiber';

import {useMediaQuery} from '@/hooks/useMediaQuery';

import type {ParticleProps} from './types';
import hexToRGB from './utils/hexToRGB';

interface ParticleClusterProps extends ParticleProps {
  vertexShader: string;
  fragmentShader: string;
  autoRotateSpeed?: number;
  positionPercent?: {x: number; y: number};
  mobilePositionPercent?: {x: number; y: number};
}

export default function ParticleCluster({
  count,
  mobileCount,
  spreadFactor = 0,
  mobileSpreadFactor = 0,
  percentageOfExpansionPoints = 0.1,
  particleSize = 20,
  animationAmplitude = 0.05,
  animationSpeed = 1,
  vertexShader,
  fragmentShader,
  autoRotateSpeed = 0.5,
  color1 = '#8ea7ff',
  color2 = '#caf389',
  positionPercent = {x: 50, y: 50},
  mobilePositionPercent = {x: 50, y: 50},
}: ParticleClusterProps) {
  const pointsRef = useRef<Points | null>(null);
  const {gl} = useThree();
  const pixelRatio = gl.getPixelRatio();
  const isMobile = useMediaQuery('(max-width: 900px)');
  const {viewport} = useThree();
  const xPercent = isMobile ? mobilePositionPercent.x : positionPercent.x;
  const yPercent = isMobile ? mobilePositionPercent.y : positionPercent.y;
  const positionX = (xPercent / 100) * viewport.width - viewport.width / 2;
  const positionY = (yPercent / 100) * viewport.height - viewport.height / 2;

  // Calculated particle size based on pixel ratio. Ensures particles are the same size on all devices while keeping hi definition on hi res screens.
  const calculatedParticleSize = useMemo(() => {
    return particleSize * pixelRatio;
  }, [particleSize, pixelRatio]);

  // Initial Particle Positions
  const particlesPosition = useMemo(() => {
    // Check that isMobile is set. Return an empty array for a minimal placeholder if its not. Prevents a flash on mobile.
    if (isMobile === null || isMobile === undefined) {
      return new Float32Array(0);
    }

    // Calculations based on mobile.
    const responsiveSpreadFactor = isMobile ? mobileSpreadFactor : spreadFactor;
    const responsiveParticleCount = isMobile
      ? mobileCount
        ? mobileCount
        : count / 3
      : count;

    // Using Fibonacci Sphere Algorighm to more uniformly distribute particles on the sphere.
    const positions = new Float32Array(count * 3);
    const distance = 1; // Normal radius of sphere.
    const goldenRatio = (1 + Math.sqrt(5)) / 2;
    const angleIncrement = Math.PI * 2 * goldenRatio;

    for (let i = 0; i < responsiveParticleCount; i++) {
      const t = i / responsiveParticleCount;
      const inclination = Math.acos(1 - 2 * t);
      const azimuth = angleIncrement * i;

      const x = Math.sin(inclination) * Math.cos(azimuth);
      const y = Math.sin(inclination) * Math.sin(azimuth);
      const z = Math.cos(inclination);

      // Randomly decide if the point should expand.
      const shouldExpand = Math.random() < percentageOfExpansionPoints;
      let finalDistance = shouldExpand
        ? distance + responsiveSpreadFactor * Math.random()
        : distance;

      positions.set(
        [finalDistance * x, finalDistance * y, finalDistance * z],
        i * 3,
      );
    }
    return positions;
  }, [
    count,
    mobileCount,
    spreadFactor,
    mobileSpreadFactor,
    percentageOfExpansionPoints,
    isMobile,
  ]);

  // Cleanup buffer when points change. Important to do this to avoid memory leaks and allow for state updates via a gui.
  useEffect(() => {
    const points = pointsRef.current;
    const geometry = new BufferGeometry();
    geometry.setAttribute(
      'position',
      new BufferAttribute(particlesPosition, 3),
    );

    if (points) {
      points.geometry.dispose();
      points.geometry = geometry;
    }
  }, [particlesPosition]);

  // Uniforms - Values we want to dynamically change in the shaders.
  const uniforms = useMemo(() => {
    const color1RGB = hexToRGB(color1);
    const color2RGB = hexToRGB(color2);

    return {
      uTime: {
        value: 0.0,
      },
      uParticleSize: {
        value: calculatedParticleSize,
      },
      uAmplitude: {
        value: animationAmplitude,
      },
      uSpeedMultiplier: {
        value: animationSpeed,
      },
      // uOpacity: {
      //   value: 1.0, // TODO: dynamically change this based on mouse events (increase opacity)
      // },
      uMouse: {
        value: new Vector2(0, 0),
      },
      uColorA: {value: new Vector3(...color1RGB)},
      uColorB: {value: new Vector3(...color2RGB)},
    };
  }, [
    calculatedParticleSize,
    animationAmplitude,
    animationSpeed,
    color1,
    color2,
  ]);

  // Frame Updates
  useFrame((state) => {
    const {clock} = state;
    const time = clock.elapsedTime;

    if (pointsRef.current) {
      // @ts-ignore
      pointsRef.current.material.uniforms.uTime.value = time;
      pointsRef.current.rotation.y = (time * autoRotateSpeed) / 10;
    }
  });

  if (isMobile === null || isMobile === undefined) return;

  return (
    <group position={[positionX, positionY, 0]}>
      <points ref={pointsRef}>
        <bufferGeometry>
          <bufferAttribute
            attach="attributes-position"
            count={particlesPosition.length / 3}
            array={particlesPosition}
            itemSize={3}
          />
        </bufferGeometry>
        <shaderMaterial
          key={`size-${particleSize}-expand-${percentageOfExpansionPoints}-amplitude-${animationAmplitude}-speed-${animationSpeed}-color1-${color1}-color2-${color2}`} // Attach the property keys to ensure the scene updates dynamically.
          depthWrite={false}
          fragmentShader={fragmentShader}
          vertexShader={vertexShader}
          uniforms={uniforms}
          transparent={true}
        />
      </points>
    </group>
  );
}
