import {useLoaderData} from '@remix-run/react';

import Section from '@/components/base/layouts/Section/Section';
import CardGrid from '@/components/base/layouts/CardGrid/CardGrid';
import SectionHeader from '@/components/base/sections/SectionHeader/SectionHeader';
import LogoGroupMarquee from '@/components/base/modules/LogoGroupMarquee/LogoGroupMarquee';
import Grid, {Col} from '@/components/base/layouts/utils/Grid/Grid';
import HeadingGroup from '@/components/base/modules/HeadingGroup/HeadingGroup';
import Card from '@/components/base/modules/Card/Card';
import ButtonGroup from '@/components/base/modules/ButtonGroup/ButtonGroup';
import Conversion from '@/components/base/sections/Conversion/Conversion';
import {useSiteData} from '@/hooks/useSiteData';
import AnimateInItem from '@/components/shared/AnimateIn/AnimateInItem';
import AnimateIn from '@/components/shared/AnimateIn/AnimateIn';

import BackgroundCornerBlocks from './shared/components/BackgroundCornerBlocks';
import HeroFullBleed from './shared/components/HeroFullBleed';
import CardWithStat from './shared/components/CardWithStat';
import FullWidthTestimonialCard from './shared/components/FullWidthTestimonialCard';
import BackgroundStaticImage from './shared/components/BackgroundStaticImage';
import TallCardCarousel from './shared/components/TallCardCarousel';
import ColorBlockCard from './shared/components/ColorBlockCard';
import SuccessStoryCard from './shared/components/SuccessStoryCard';
import HeroParticles from './shared/components/particles/HeroParticles';
import type {loader} from './server/loader.server';
import FullWidthVideoCard from './shared/components/FullWidthVideoCard';

export {handle} from './handle';
export {loader} from './server/loader.server';

export default function PartnersHomepage() {
  const {content, settings, loopingLogos} = useLoaderData<typeof loader>();
  const {getUrl} = useSiteData();

  return (
    <>
      {/* Hero */}
      <Section
        className="relative bg-off-white gap-y-0 overflow-hidden pt-14"
        mode="light"
        topSpacing="none"
        bottomSpacing="2xl"
      >
        <div className="absolute w-full h-full inset-0">
          <HeroParticles />
        </div>
        <HeroFullBleed
          headingGroupProps={{
            kicker: content.hero.kicker,
            headingHtml: content.hero.headingHtml,
            subheadHtml: content.hero.subheadHtml,
          }}
          buttonProps={{
            children: content.hero.button.children,
            href: getUrl(settings.hero.link.path, settings.hero.link.key),
            componentName: settings.hero.link.componentName,
          }}
        />
        <LogoGroupMarquee logos={loopingLogos} />
      </Section>

      {/* Reasons To Join */}
      <Section className="relative bg-black" mode="dark">
        <BackgroundCornerBlocks position="top-right-minimal" />
        <SectionHeader
          mode="dark"
          kicker={content.reasonsToJoin.headingGroup.kicker}
          kickerAs="h2"
          headingHtml={content.reasonsToJoin.headingGroup.headingHtml}
          headingAs="h3"
          subheadHtml={content.reasonsToJoin.headingGroup.subheadHtml}
          className="relative"
        />
        <AnimateIn effect="rise">
          <CardGrid
            numberOfColumns={3}
            withContainer
            className="max-md:gap-lg max-lg:gap-md"
          >
            {content.reasonsToJoin.statCards.map((card, idx) => (
              <AnimateInItem key={card.statText} index={idx}>
                <CardWithStat
                  key={card.statText}
                  headingGroup={{
                    className: '[&_h3]:text-t5',
                    headingAs: 'h3',
                    headingHtml: card.headingGroup.headingHtml,
                    subheadHtml: card.headingGroup.subheadHtml,
                  }}
                  mode="dark"
                  statNumber={card.statNumber}
                  statText={card.statText}
                />
              </AnimateInItem>
            ))}
          </CardGrid>
        </AnimateIn>
      </Section>

      {/* Ways to partner */}
      <Section className="relative partners-background-grid bg-off-white">
        <Grid>
          <Col span={{sm: 12, md: 4}}>
            <HeadingGroup
              kicker={content.sideBySide.headingGroup.kicker}
              kickerAs="h2"
              headingAs="h3"
              headingHtml={content.sideBySide.headingGroup.headingHtml}
              subheadHtml={content.sideBySide.headingGroup.subheadHtml}
              className="md:mr-10"
            />
          </Col>
          <Col span={{sm: 12, md: 8}}>
            <TallCardCarousel
              cards={content.sideBySide.cards}
              cardSettings={settings.sideBySide.cards}
            />
          </Col>
        </Grid>
      </Section>

      {/* Partner Benefits */}
      <Section className="relative bg-black">
        <BackgroundCornerBlocks position="top-right" />
        <SectionHeader
          mode="dark"
          kicker={content.partnerBenefits.headingGroup.kicker}
          headingHtml={content.partnerBenefits.headingGroup.headingHtml}
          subheadHtml={content.partnerBenefits.headingGroup.subheadHtml}
          kickerAs="h2"
          headingAs="h3"
          className="relative"
        />
        <AnimateIn effect="fade">
          <CardGrid
            numberOfColumns={3}
            withContainer
            className="relative gap-lg max-md:gap-xl"
          >
            {content.partnerBenefits.cards.map((card, idx) => (
              <AnimateInItem key={card.headingGroup.headingHtml} index={idx}>
                <Card
                  size="small"
                  image={{
                    src: settings.partnerBenefits.cards[idx].image,
                    alt: card.image.alt,
                  }}
                  headingGroup={{
                    headingHtml: card.headingGroup.headingHtml,
                    subheadHtml: card.headingGroup.subheadHtml,
                    headingAs: 'h3',
                  }}
                  mode="dark"
                />
              </AnimateInItem>
            ))}
          </CardGrid>
        </AnimateIn>
        <FullWidthVideoCard
          video={{
            video: {
              webmSrc: settings.partnerBenefits.fullWidthCard.video.webmSrc,
              mp4Src: settings.partnerBenefits.fullWidthCard.video.mp4Src,
              image: {
                src: settings.partnerBenefits.fullWidthCard.video.image.src,
                alt: content.partnerBenefits.fullWidthCard.video.image.alt,
              },
            },
            showPlayButton: false,
          }}
          mediaClassName="bg-gradient-to-b from-dark-blue to-dusky-blue full-width-card-inline-video"
          mode="light"
          visualPosition="left"
        >
          <HeadingGroup
            kicker={content.partnerBenefits.fullWidthCard.headingGroup.kicker}
            headingHtml={
              content.partnerBenefits.fullWidthCard.headingGroup.headingHtml
            }
            mode="light"
            size="t3"
            subheadHtml={
              content.partnerBenefits.fullWidthCard.headingGroup.subheadHtml
            }
            kickerAs="h2"
            headingAs="h3"
          />
          <ButtonGroup
            buttons={content.partnerBenefits.fullWidthCard.buttons.map(
              (button, idx) => {
                const buttonSettings =
                  settings.partnerBenefits.fullWidthCard.buttons[idx];

                return {
                  href: getUrl(buttonSettings.url.path, buttonSettings.url.key),
                  intent: buttonSettings.intent,
                  text: button.text,
                  componentName: buttonSettings.componentName,
                };
              },
            )}
            mode="light"
          />
        </FullWidthVideoCard>
      </Section>

      {/* Success Stories */}
      <Section
        className="relative partners-background-grid bg-off-white gap-y-0 before:bg-black before:absolute before:bottom-0 before:left-0 before:w-full before:h-56"
        bottomSpacing="2xl"
      >
        <SectionHeader
          mode="light"
          kicker={content.successStories.sectionHeader.headingGroup.kicker}
          headingHtml={
            content.successStories.sectionHeader.headingGroup.headingHtml
          }
          subheadHtml={
            content.successStories.sectionHeader.headingGroup.subheadHtml
          }
          kickerAs="h2"
          headingAs="h3"
          className="mb-10 md:mb-16"
        />
        <FullWidthTestimonialCard
          mode="dark"
          quoteHtml={content.successStories.testimonial.quoteHtml}
          author={content.successStories.testimonial.author}
          authorTitle={content.successStories.testimonial.authorTitle}
          brand={content.successStories.testimonial.brand}
          isFullSpan
          hideImageOnMobile={false}
          link={{
            text: content.successStories.testimonial.link.text,
            href: getUrl(
              settings.successStories.testimonial.link.path,
              settings.successStories.testimonial.link.key,
            ),
          }}
          image={{
            ...settings.successStories.testimonial.image,
            alt: content.successStories.testimonial.image.alt,
          }}
          containerClassName="mb-6"
        />
        <Grid className="gap-x-6 gap-y-6 relative">
          <Col span={{xs: 12, sm: 12, md: 4}}>
            <ColorBlockCard
              className="bg-blue-lilac"
              headingGroup={{
                kicker: content.successStories.card.headingGroup.kicker,
                headingHtml:
                  content.successStories.card.headingGroup.headingHtml,
                subheadHtml:
                  content.successStories.card.headingGroup.subheadHtml,
              }}
              link={{
                text: content.successStories.card.link.text,
                href: getUrl(
                  settings.successStories.card.link.path,
                  settings.successStories.card.link.key,
                ),
              }}
              mode="light"
            />
          </Col>
          <Col span={{xs: 12, sm: 12, md: 8}}>
            <SuccessStoryCard
              className="h-full"
              headginGroup={{
                kicker:
                  content.successStories.fullWidthCard.headingGroup.kicker,
                headingHtml:
                  content.successStories.fullWidthCard.headingGroup.headingHtml,
                subheadHtml:
                  content.successStories.fullWidthCard.headingGroup.subheadHtml,
              }}
              link={{
                href: getUrl(
                  settings.successStories.fullWidthCard.link.path,
                  settings.successStories.fullWidthCard.link.key,
                ),
                text: content.successStories.fullWidthCard.link.text,
              }}
              image={{
                ...settings.successStories.fullWidthCard.image,
                ...content.successStories.fullWidthCard.image,
                loading: 'lazy',
              }}
            />
          </Col>
        </Grid>
      </Section>

      {/* Conversion */}
      <Section
        className="relative bg-black"
        topSpacing="2xl"
        bottomSpacing="4xl"
      >
        <BackgroundStaticImage
          imageName="pixels-bottom"
          altText={content.conversion.image.alt}
        />
        <Conversion
          className="relative"
          mode="dark"
          kicker={content.conversion.headingGroup.kicker}
          headingHtml={content.conversion.headingGroup.headingHtml}
          subheadHtml={content.conversion.headingGroup.subheadHtml}
          buttons={content.conversion.buttons.map((button, idx) => {
            const buttonSettings = settings.conversion.buttons[idx];
            return {
              href: getUrl(buttonSettings.link.path, buttonSettings.link.key),
              componentName: buttonSettings.link.componentName,
              text: button.text,
            };
          })}
        />
      </Section>
    </>
  );
}
