// Helper function to convert hex to rgb.
// Not using THREE.Color because the outputs were incorrect.
// Inspiration https://airtightinteractive.com/util/hex-to-glsl/

export default function hexToRGB(hexStr: string) {
  if (/^#([0-9A-F]{3}){1,2}$/i.test(hexStr)) {
    let r, g, b;
    if (hexStr.length === 4) {
      r = parseInt(hexStr[1] + hexStr[1], 16);
      g = parseInt(hexStr[2] + hexStr[2], 16);
      b = parseInt(hexStr[3] + hexStr[3], 16);
    } else if (hexStr.length === 7) {
      r = parseInt(hexStr.substring(1, 3), 16);
      g = parseInt(hexStr.substring(3, 5), 16);
      b = parseInt(hexStr.substring(5, 7), 16);
    }
    if (r && g && b) {
      return [r / 255, g / 255, b / 255];
    } else {
      return [0, 0, 0];
    }
  } else {
    return [0, 0, 0];
  }
}
