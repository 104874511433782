// Fragment Shader - Paints the particles
export const fragmentShader = `
  varying vec3 vPosition;
  varying vec2 vUv;
  varying float vDepth;

  uniform vec3 uColorA;
  uniform vec3 uColorB;

  void main() {
    // Normalize position between 0 and 1
    float n = (vPosition.x + 1.0) / 2.0; // Horizontal Gradient
    vec3 colorMix = mix(uColorA, uColorB, n); // Mix color based on x-coordinate

    // Calculate opacity based on depth
    float nearPlane = 3.5;
    float farPlane = 6.5;
    float depthOpacity = 1.0 - smoothstep(nearPlane, farPlane, vDepth);
    gl_FragColor = vec4(colorMix, depthOpacity);
  }
`;
