import {Canvas} from '@react-three/fiber';
import {useEffect, useRef, useState} from 'react';

import {twMerge} from '@/stylesheets/twMerge';
import ParticleCluster from '@/pages/shopify.com/($locale)/partners/shared/components/particles/ParticleCluster';

import {vertexShader} from './shaders/vertexShader';
import {fragmentShader} from './shaders/fragmentShader';

interface PartnerParticleProps {
  className?: string;
}

/*--------------------
Scenes
--------------------*/
export default function HeroParticles({className = ''}: PartnerParticleProps) {
  const canvasRef = useRef(null);
  const [frameLoop, setFrameLoop] = useState<
    'always' | 'never' | 'demand' | undefined
  >('always');
  const [hasLoaded, setHasLoaded] = useState(false);

  useEffect(() => {
    if (!canvasRef.current) return;
    const currentCanvas = canvasRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        const isVisible = entries[0].isIntersecting;
        setFrameLoop(isVisible ? 'always' : 'never');
      },
      // Trigger when 10% of the element is in view and give a bit of space around the element so its already playing when it comes into full view.
      {threshold: 0.1, rootMargin: '100px 0px 100px 0px'},
    );

    if (currentCanvas) {
      observer.observe(currentCanvas);
    }

    return () => {
      if (currentCanvas) {
        observer.unobserve(currentCanvas);
      }
    };
  }, []);

  return (
    <div
      className={twMerge(
        'w-full h-full relative transition-opacity duration-1000',
        className,
        hasLoaded ? 'opacity-100' : 'opacity-0',
      )}
      ref={canvasRef}
    >
      <Canvas
        camera={{position: [0, 0, 4.5], fov: 30}}
        frameloop={frameLoop}
        onCreated={() => setHasLoaded(true)}
      >
        {/* Top Right */}
        <ParticleCluster
          positionPercent={{x: 90, y: 85}}
          mobilePositionPercent={{x: 115, y: 110}}
          count={1000}
          mobileCount={500}
          spreadFactor={0.6}
          mobileSpreadFactor={0.0}
          percentageOfExpansionPoints={0.1}
          particleSize={6}
          animationAmplitude={0.05}
          animationSpeed={0.5}
          vertexShader={vertexShader}
          fragmentShader={fragmentShader}
          autoRotateSpeed={0.5}
          color1="#8ea7ff"
          color2="#c3d7a7"
        />
        {/* Bottom Left */}
        <ParticleCluster
          positionPercent={{x: 5, y: -10}}
          mobilePositionPercent={{x: 0, y: -10}}
          count={100}
          spreadFactor={0.03}
          percentageOfExpansionPoints={0.01}
          particleSize={6}
          animationAmplitude={0.08}
          animationSpeed={0.5}
          vertexShader={vertexShader}
          fragmentShader={fragmentShader}
          color1="#8ea7ff"
          color2="#8ea7ff"
        />
      </Canvas>
    </div>
  );
}
