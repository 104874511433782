import Section from '@/components/base/layouts/Section/Section';
import type {HeroProps} from '@/components/base/sections/Hero/types';
import type {HeadingGroupProps} from '@/components/base/modules/HeadingGroup/HeadingGroup';
import SectionHeader from '@/components/base/sections/SectionHeader/SectionHeader';
import Button, {
  type ButtonProps,
} from '@/components/base/elements/Button/Button';
interface HeroFullBleedProps extends HeroProps {
  headingGroupProps: HeadingGroupProps;
  buttonProps: ButtonProps;
}

export default function HeroFullBleed({
  headingGroupProps,
  buttonProps,
}: HeroFullBleedProps) {
  return (
    <Section
      className="bg-transparent relative max-md:py-10 gap-y-lg"
      data-component-name="hero"
    >
      <SectionHeader
        size="dsp"
        kickerAs="h1"
        headingAs="h2"
        className="[&_h2]:[font-size:52px] md:[&_h2]:[font-size:78px]"
        {...headingGroupProps}
      />
      <div className="container">
        <Button {...buttonProps}>{buttonProps.children}</Button>
      </div>
    </Section>
  );
}
