// Vertex Shader - Move the particles
export const vertexShader = `
  uniform float uTime;
  uniform float uParticleSize;
  uniform float uAmplitude;
  uniform float uSpeedMultiplier;
  // uniform vec2 uMouse;

  varying vec3 vPosition;
  varying float vDepth;

  void main() {
    // Unique seed for randomness based on position
    vec3 seed = position * 12.9898 + 78.233;
    float randomX = fract(sin(dot(seed, vec3(12.9898, 78.233, 45.164))) * 43758.5453);
    float randomY = fract(sin(dot(seed, vec3(45.164, 12.9898, 78.233))) * 43758.5453);

    // Set the x and y offset randomly.
    vec3 offset = vec3(
        uAmplitude * sin(uTime * uSpeedMultiplier * randomX),
        uAmplitude * sin(uTime * uSpeedMultiplier * randomY),
        0.0
    );

    // Set the model position and apply the offset to get the random movement.
    vec4 modelPosition = modelMatrix * vec4(position + offset, 1.0);
    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectedPosition = projectionMatrix * viewPosition;
    vDepth = -viewPosition.z;

    gl_Position = projectedPosition;
    gl_PointSize = uParticleSize;
    vPosition = position;
  }
`;
