import {cva} from 'class-variance-authority';

import Grid, {Col} from '@/components/base/layouts/utils/Grid/Grid';
import Image from '@/components/base/elements/Image/Image';
import {type HeadingGroupProps} from '@/components/base/modules/HeadingGroup/HeadingGroup';
import {type ImageProps} from '@/components/base/elements/Image/Image';
import {type LinkProps} from '@/components/base/elements/Link/Link';
import {twMerge} from '@/stylesheets/twMerge';

import ColorBlockCard from './ColorBlockCard';

const imageStyles = cva(
  'bg-lime px-9 py-[30px] flex items-center justify-center rounded-2xl md:rounded-r-none',
  {
    variants: {
      hideImageOnMobile: {
        true: 'max-md:hidden',
        false: '',
      },
    },
  },
);

interface SuccessStoryCard {
  headginGroup: HeadingGroupProps;
  image: ImageProps;
  link: LinkProps;
  hideImageOnMobile?: boolean;
  className?: string;
}

export default function SuccessStoryCard({
  headginGroup,
  image,
  link,
  hideImageOnMobile = true,
  className,
}: SuccessStoryCard) {
  return (
    <Grid
      className={twMerge(
        'remove-container gap-0 card-box-shadow rounded-2xl overflow-hidden',
        className,
      )}
    >
      <Col
        span={{xs: 8, md: 6}}
        className={twMerge(imageStyles({hideImageOnMobile}))}
      >
        {image && <Image {...image} />}
      </Col>
      <Col span={{xs: 8, md: 6}} className="bg-white">
        <ColorBlockCard
          headingGroup={{
            className: '[&_h2]:text-t5',
            headingAs: 'h2',
            ...headginGroup,
          }}
          link={link}
          mode="light"
          disableShadow={true}
        />
      </Col>
    </Grid>
  );
}
