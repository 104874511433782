import Card, {type CardProps} from '@/components/base/modules/Card/Card';

import StatCard from './StatCard';

interface CardWithStatProps extends CardProps {
  statNumber: string;
  statText: string;
}

export default function CardWithStat({
  statNumber,
  statText,
  ...props
}: CardWithStatProps) {
  return (
    <div className="h-full flex flex-col" data-component-name="card-with-stat">
      <Card
        {...props}
        className="px-6 md:px-8 pt-6 md:pt-8 pb-6 md:pb-14 bg-dark-blue rounded-none rounded-t-2xl rounded-b-0 flex-grow"
      />
      <StatCard
        statNumber={statNumber}
        statText={statText}
        placement="bottom"
      />
    </div>
  );
}
